  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
.socal_card{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.socal_cardlist p{
    font-size: 20px;
}
.socal_cardlist p span{
    font-size: 1.5rem;
    margin-left: 10px;
    font-weight: 600;
    /* border-bottom: 2px solid #000; */
}
.data_card{
    margin-left: 32px;
    margin-top: 5px;
}

.card_body {
    font-family: Montserrat,sans-serif!important;
    font-weight: 400;
    scroll-behavior: smooth;
    background-color: #1A1824;
}

*,:focus {
    outline: 0
}

a,button,img,input,select,textarea {
    -webkit-tap-highlight-color: transparent
}

*,figure,li,ol,ul {
    padding: 0;
    margin: 0
}

.content-card,.main,.profile-image {
    position: relative
}

.designation p {
    font-size: 12px;
    color: #fff;
    text-align: center
}
.username p {
    font-size: 16px;
    color: #fff;
    text-align: center
}

.close-modal,a,button {
    cursor: pointer
}

.designation p,.personalBio p,.profile-card,.profile-image,.row1 .wrapper,.username p {
    text-align: left
}

* {
    border: 0;
    box-sizing: border-box;
    -webkit-appearance: value;
    -moz-appearance: value;
    appearance: value;
    word-break: break-word
}

a,abbr,acronym,address,applet,b,big,blockquote,body,caption,center,cite,code,del,dfn,em,fieldset,font,form,h1,h2,h3,h4,h5,h6,html,i,iframe,img,ins,kbd,label,legend,object,p,pre,q,s,samp,small,span,strike,strong,sub,sup,table,tbody,td,tfoot,th,thead,tr,tt,u,var {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%
}

a,img a {
    text-decoration: none;
    border: 0;
    outline: 0!important;
    transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    -ms-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    -webkit-transition: .3s ease-in
}

a,button,input {
    transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    -ms-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    -webkit-transition: .3s ease-in
}

table {
    border-collapse: collapse;
    border-spacing: 0px
}

li,ol,ul {
    list-style-type: none
}

button,img {
    user-select: none;
    -webkit-user-drag: auto
}

a,img {
    -moz-tap-highlight-color: transparent
}

a:active,a:focus,a:hover {
    text-decoration: none!important
}

.clear,.clearfix:after {
    clear: both
}

.clearfix:after,.clearfix:before {
    content: " ";
    display: table
}

.full-height,body,html {
    height: 100%
}

input::-webkit-inner-spin-button,input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

body {
    font-family: Montserrat,sans-serif!important;
    font-weight: 400;
    scroll-behavior: smooth;
    background-color: #1A1824
}

.main {
    min-height: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 0 50px
}



.profile-card {
    width: 100%;
    max-height: 212px;
    margin: 0 auto;
    background: #000;
    border-radius: 0 0 8px 8px
}

.content-card {
    margin: 0px auto 0;
    max-width: 92%;
    padding: 0 15px;
    border: 2px solid #8080807d;
    border-radius: 11px;
    background: #1A1824
}

.personalBio,.profile-button {
    margin-bottom: 20px
}

@media(max-width: 400px) {
    .content-card {
        max-width:92%
    }
}

.profile-image .profile-avatar {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin: -50px auto 0;
    border: 1px solid #626262
}

.profile-card img {
    width: 100%;
    height: 100%;
    display: block
}

.profile-image .company-logo {
    width: 35px;
    height: 35px;
    margin-left: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    left: 77%;
    top: 58%;
    transform: translate(-50%,-50%)
}

.username p {
    font-weight: 600;
}
.profile-details {
    margin-top:10px;
}
.designation p span {
    color: #fff
}

.profile-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-button .connect-btn {
    border-radius: 30px;
    color: #fff;
    margin: 5px;
    font-size: 12px;
    font-weight: 400;
    padding: 7px 17px;
    background-color: #181924;
    border: 0.5px solid grey;
    text-transform: capitalize;
}

.profile-button .profilesave-btn {
    background-color: #181924;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    border: .5px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -12px 0 10px;
    position: absolute;
    right: 30px
}

.profile-button .profilesave-btn svg {
    width: 25px
}

.personalBio p {
    font-size: 12px;
    /* margin: 12px 0 0; */
    color: #E1AC4C
}

.get-button button {
    background-color: #00aff0;
    border: .5px solid grey;
    border-radius: 31px;
    color: #fff;
    font-size: 18px;
    width: 300px;
    padding: 8px 10px;
    position: fixed;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%)
}

.modal-container {
    display: none;
    position: fixed;
    inset: 0;
    z-index: 10000;
    background-color: rgba(0,0,0,.75);
    padding: 40px;
    overflow: auto
}

.modal-container.open {
    display: flex;
    justify-content: center;
    align-items: center
}

.modal-body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 360px;
    background-color: #fff;
    border-radius: 10px
}

.close-modal {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 20px
}

.modal-header h3 {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    max-width: 240px;
    line-height: 1.7;
    color: #272727
}

.divider {
    width: 100%;
    height: 1px;
    background-color: grey;
    margin: 1rem 0
}

.input-group {
    position: relative;
    margin: 0 0 15px
}

.user-label {
    position: absolute;
    left: 15px;
    color: grey;
    pointer-events: none;
    font-family: var(--font-family);
    transition: 150ms cubic-bezier(.4, 0, .2, 1);
    top: 50%;
    transform: translateY(-50%);
    line-height: normal
}

.input:focus~label,input:valid~label {
    transform: translateY(-141%) scale(.8);
    padding: 0 .2em;
    background-color: #fff;
    color: #686868
}

.input {
    width: 100%;
    border: 1.5px solid #9e9e9e;
    border-radius: 5px;
    background: 0 0;
    padding: 12px 16px;
    font-size: 14px;
    color: #222;
    transition: border 150ms cubic-bezier(.4, 0, .2, 1)
}

.connect-button button {
    width: 100%;
    padding: 5%;
    margin: 4% 0;
    border-radius: 20px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer
}

.row1 .wrapper,.row1 img {
    margin: 0 0 13px;
    width: 27%
}

.row1 {
    display: flex;
    flex-flow: wrap;
    max-width: 100%;
    margin: 19px auto;
}

.row1 .wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    align-items: center;
    width: 33%;
}

.row1 .wrapper .alt-text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff
}

.row1 .wrapper img {
  
    border-radius: 1rem;
    margin: 0 0 10px;
    width: 115px !important;
    height: 115px !important;

}

.modaicon {
    display:block !important;
    top: 25%;
    left: 23%;
    background-color:#fff
}
.blue-tick {
    width: 15px;
    margin-left: 10px;
}

@media(max-width: 450px) {
    .modaicon {
        left: 3%;
    }
}


@media(max-width: 500px) {
    .main {
        max-width:100%;
        margin: 0 auto
    }

    .profile-image .profile-avatar {
        width: 80px !important;
        height: 80px  !important;
        margin: -28px auto 0;

    }

    .content-card {
        padding: 0 10px !important;
    }
    .profile-button .connect-btn { 
        padding: 7px 15px;
    }

    .row1 .wrapper img {
        width: 100px !important;
        height: 100px !important;
    }
    .blue-tick {
        width: 12px;
    }
}